export enum trackLocationsTabEnum {
  SELECTED_TRACK_LOCATIONS = 'Selected Track Locations',
  LIBRARY_TRACK_LOCATIONS = 'Library Track Locations',
}

export enum recordingDataTableHeadersEnum {
  RAILROAD = 'railroad',
  DIVISION = 'division',
  SUBDIVISION = 'subdivision',
  ELR = 'elr',
  TRACK_ID = 'track_id',
  START_POST = 'miles_start',
  END_POST = 'miles_end',
  START_POST_KM = 'kilometers_start',
  END_POST_KM = 'kilometers_end',
  START_PULSE = 'pulse_count_start',
  END_PULSE = 'pulse_count_end',
  TEST = 'test',
  COMMENT = 'comment',
  RAIL = 'rail',
  COPYLINK = 'copylink',
  CHECKBOX = 'checkbox',
}

export enum trackLocationsTableHeadersEnum {
  RAILROAD = 'custname',
  DIVISION = 'Division',
  SUBDIVISION = 'sub_division',
  ELR = 'sub_divisionAbv',
  TRACK_ID = 'trackAliasAbv',
  START_POST = 'milestart',
  END_POST = 'mileend',
  START_POST_CHANGEABLE = 'mile_start',
  END_POST_CHANGEABLE = 'mile_end',
  START_PULSE = 'pulse_count_start',
  END_PULSE = 'pulse_count_end',
  TEST = 'test',
  COMMENT = 'comment',
  RAIL = 'rail',
  COPYLINK = 'copylink',
  CHECKBOX = 'checkbox',
}

export const selectedTrackLocationsTableHeadersWithSortingOptionList = [
  trackLocationsTableHeadersEnum.TRACK_ID,
  trackLocationsTableHeadersEnum.START_POST,
  trackLocationsTableHeadersEnum.END_POST,
  trackLocationsTableHeadersEnum.START_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.END_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.START_PULSE,
  trackLocationsTableHeadersEnum.END_PULSE,
  trackLocationsTableHeadersEnum.TEST,
  trackLocationsTableHeadersEnum.COMMENT,
  trackLocationsTableHeadersEnum.RAIL,
];

export const libraryTrackLocationsTableHeadersWithSortingOptionList = [
  trackLocationsTableHeadersEnum.START_POST,
  trackLocationsTableHeadersEnum.END_POST,
  trackLocationsTableHeadersEnum.START_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.END_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.START_PULSE,
  trackLocationsTableHeadersEnum.END_PULSE,
  trackLocationsTableHeadersEnum.TEST,
  trackLocationsTableHeadersEnum.COMMENT,
  trackLocationsTableHeadersEnum.RAIL,
];

export const libraryTrackLocationHeadersWithAction = [
  trackLocationsTableHeadersEnum.RAILROAD,
  trackLocationsTableHeadersEnum.DIVISION,
  trackLocationsTableHeadersEnum.SUBDIVISION,
  trackLocationsTableHeadersEnum.ELR,
  trackLocationsTableHeadersEnum.TRACK_ID,
  trackLocationsTableHeadersEnum.START_POST,
  trackLocationsTableHeadersEnum.END_POST,
  trackLocationsTableHeadersEnum.START_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.END_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.START_PULSE,
  trackLocationsTableHeadersEnum.END_PULSE,
];

export const selectedTrackLocationsHeadersWithAction = [
  trackLocationsTableHeadersEnum.TRACK_ID,
  trackLocationsTableHeadersEnum.START_POST,
  trackLocationsTableHeadersEnum.END_POST,
  trackLocationsTableHeadersEnum.START_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.END_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.START_PULSE,
  trackLocationsTableHeadersEnum.END_PULSE,
  trackLocationsTableHeadersEnum.TEST,
  trackLocationsTableHeadersEnum.COMMENT,
];

export const untestedTrackLocationsHeadersWithAction = [
  trackLocationsTableHeadersEnum.TRACK_ID,
  trackLocationsTableHeadersEnum.START_POST,
  trackLocationsTableHeadersEnum.END_POST,
  trackLocationsTableHeadersEnum.START_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.END_POST_CHANGEABLE,
  trackLocationsTableHeadersEnum.START_PULSE,
  trackLocationsTableHeadersEnum.END_PULSE,
  trackLocationsTableHeadersEnum.TEST,
  trackLocationsTableHeadersEnum.COMMENT,
  trackLocationsTableHeadersEnum.RAIL,
];

export const MILEPOST_VALUE_ROUND_TO_ONE_THOUSAND_PART = 1000;

export const multiSelectFilter = (rows, columnIds, filterValue): void =>
  filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.includes(row.original[columnIds]));

export const testOptions = [
  { value: 'Tested', label: 'Tested' },
  { value: 'Untested', label: 'Untested' },
  { value: 'Unknown', label: 'Unknown' },
];

export enum trackLocationsTypesEnum {
  TESTED = 'Tested',
  UNKNOWN = 'Unknown',
  UNTESTED = 'Untested',
}

// TODO remove when BE makes API call
export const commentsForUnknownOptions = [
  { value: 'Off Route', label: 'Off Route' },
  { value: 'Unknown', label: 'Unknown' },
];

// TODO remove when BE makes API call
export const commentsForUntestedOptions = [
  { value: 'Access Denied/Track Closure', label: 'Access Denied/Track Closure' },
  { value: 'Between Test Runs', label: 'Between Test Runs' },
  { value: 'Customer Hours', label: 'Customer Hours' },
  { value: 'Customer Operational Issues', label: 'Customer Operational Issues' },
  { value: 'Driver Speeding', label: 'Driver Speeding' },
  { value: 'Late Departure/Running', label: 'Late Departure/Running' },
  { value: 'Off Route', label: 'Off Route' },
  { value: 'Sperry Couplant System Failure', label: 'Sperry Couplant System Failure' },
  { value: 'Sperry Hours', label: 'Sperry Hours' },
  { value: 'Sperry Operational Issues', label: 'Sperry Operational Issues' },
  { value: 'Sperry RSU Failure', label: 'Sperry RSU Failure' },
  { value: 'Sperry RSU Positioning Failure', label: 'Sperry RSU Positioning Failure' },
  { value: 'Sperry System Failure', label: 'Sperry System Failure' },
  { value: 'Test Started Early', label: 'Test Started Early' },
  { value: 'Test Started Late', label: 'Test Started Late' },
];

export enum interlockedCommentsEnum {
  OFF_ROUTE = 'Off Route',
}

export const railsOptions = [
  { value: 'Both', label: 'Both' },
  { value: 'Top', label: 'Top' },
  { value: 'Bottom', label: 'Bottom' },
];

export enum defaultCommentsValuesEnum {
  EMPTY = '',
  NA = 'N/A',
}

export const NA_VALUE = 'N/A';
export const elrAndTrackIdValueForUnknownTrackLocations = 'Unknown';

export const INITIAL_PAGINATION_AMOUNT = 16;
export const MEDIUM_ROW_INDEX_FOR_DROPDOWN_POSITION = 5;
export const TABLE_ROW_HEIGTH = 48;

export const RECORDING_DATA_TABLE_ALL_COLUMN_WIDTH = 1080;
export const CHECKBOX_COLOMN_WIDTH = 36;
