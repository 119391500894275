import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { REACT_APP_BACKEND_URL } from 'shared/envs';
import { ONE_HOUR_IN_MILLISECONDS } from 'shared/constants/common';

// const DEFAULT_ATTEMPTS_FOR_REFRESH_TOKEN = 1;
const UNAUTHORIZED_STATUS_CODE = 401;
const TOKEN_EXPIRED_MESSAGE_FROM_BACKEND = 'The incoming token has expired';
export const AUTHORIZATION_HEADER = 'Authorization';

const config: AxiosRequestConfig = {
  baseURL: REACT_APP_BACKEND_URL,
  headers: {
    'Content-type': 'application/json',
    [AUTHORIZATION_HEADER]: `Bearer ${localStorage.getItem('idToken')}`,
  },
};

export const axiosInstance: AxiosInstance = axios.create(config);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // const attemptsForRefreshToken =
    //   originalRequest.attemptsForRefreshToken ?? DEFAULT_ATTEMPTS_FOR_REFRESH_TOKEN;

    // TODO: remove this console.log after feature will be tested
    console.log('error from axios interceptor errors middleware: ', { ...error });

    if (
      error.response?.status === UNAUTHORIZED_STATUS_CODE ||
      error.message === TOKEN_EXPIRED_MESSAGE_FROM_BACKEND ||
      error.response?.data?.message === TOKEN_EXPIRED_MESSAGE_FROM_BACKEND ||
      error.response?.data?.error === TOKEN_EXPIRED_MESSAGE_FROM_BACKEND
    ) {
      try {
        const user = await Auth.currentAuthenticatedUser();

        const idTokenRefreshed = user.getSignInUserSession().getIdToken().getJwtToken();
        const accessTokenRefreshed = user.getSignInUserSession().getAccessToken().getJwtToken();

        localStorage.setItem('idToken', idTokenRefreshed);
        localStorage.setItem('accessToken', accessTokenRefreshed);
        axiosInstance.defaults.headers[AUTHORIZATION_HEADER] = `Bearer ${idTokenRefreshed}`;
        originalRequest.headers[AUTHORIZATION_HEADER] = `Bearer ${idTokenRefreshed}`;
        const expireCookieTime = Date.now() + ONE_HOUR_IN_MILLISECONDS;
        document.cookie = `idToken=${idTokenRefreshed}; path=/; domain=${window.location.hostname.replace(
          'viewer',
          ''
        )}; expires=${new Date(expireCookieTime).toUTCString()};`;
        // originalRequest.attemptsForRefreshToken = attemptsForRefreshToken - 1;

        // if (!originalRequest.attemptsForRefreshToken) {
        //   delete originalRequest.attemptsForRefreshToken;
        // }
      } catch (err) {
        Auth.federatedSignIn();
      }

      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);
